<template>
  <div>
    <content-block title="员工基本信息">
      <el-descriptions :column="3" border>
        <el-descriptions-item label="用户名">
          {{ userInfo.name }}
          <span class="text-primary ml-1 cursor-pointer" @click="updateNameDialogVisible = true">修改</span>
        </el-descriptions-item>
        <el-descriptions-item label="手机号">
          {{ userInfo.phoneNumber }}
        </el-descriptions-item>
        <el-descriptions-item label="直属部门">
          {{ userInfo.departmentName }}
        </el-descriptions-item>
        <el-descriptions-item label="员工状态">
          {{ userInfo.statusName }}
        </el-descriptions-item>
        <el-descriptions-item label="邮箱地址">
          {{ userInfo.email }}
        </el-descriptions-item>
        <el-descriptions-item label="角色权限">
          <template v-if="userInfo.roleNamesStr">
            <el-tag size="mini" class="mr-1" v-for="(i, index) in userInfo.roleNamesStr.split(',')" :key="index">
              {{ i }}
            </el-tag>
          </template>
          <span v-else>--</span>
        </el-descriptions-item>
      </el-descriptions>
    </content-block>
    <content-block title="员工账号信息">
      <el-descriptions :column="3" border>
        <el-descriptions-item label="账号名称">
          {{ userInfo.phoneNumber }}
        </el-descriptions-item>
        <el-descriptions-item label="密码">
          ******
          <span class="text-primary ml-1 cursor-pointer" @click="updatePwdDialogVisible = true">修改</span>
        </el-descriptions-item>
        <el-descriptions-item label="账号状态">
          {{ userInfo.accountStatusName }}
        </el-descriptions-item>
      </el-descriptions>
    </content-block>
    <el-dialog title="修改姓名" width="460px" :visible.sync="updateNameDialogVisible" :close-on-click-modal="false">
      <el-form ref="updateNameForm" :model="updateNameForm" :rules="updateNameFormRules" label-width="80px">
        <el-form-item label="原姓名">
          {{ userInfo.name }}
        </el-form-item>
        <el-form-item label="新姓名" prop="name">
          <el-input v-model.trim="updateNameForm.name" placeholder="请输入新姓名"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="updateNameDialogVisible = false">关闭</el-button>
        <el-button type="primary" @click="handleSaveName">保存</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改密码" width="460px" :visible.sync="updatePwdDialogVisible" :close-on-click-modal="false">
      <el-form ref="updatePwdForm" :model="updatePwdForm" :rules="updatePwdFormRules" label-width="120px">
        <el-form-item prop="phone" label="手机号">
          <div>{{ updatePwdForm.phone }}</div>
        </el-form-item>
        <el-form-item prop="code" label="验证码">
          <el-input placeholder="请输入短信验证码" type="text" autocomplete="off" v-model="updatePwdForm.code">
            <span
              slot="append"
              :class="['cursor-pointer', 'send_btn', loginMsgTimerTick > 0 && 'disabled_send']"
              @click="handleCheckCode"
            >
              {{ loginMsgTip }}
            </span>
          </el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPwd">
          <el-input
            type="password"
            v-model.trim="updatePwdForm.newPwd"
            placeholder="请输入新密码"
            show-password
            autocomplete="new-password"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="repeatPwd">
          <el-input
            type="password"
            v-model.trim="updatePwdForm.repeatPwd"
            placeholder="请输入确认密码"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="updatePwdDialogVisible = false">关闭</el-button>
        <el-button type="primary" @click="handleUpdatePwd">保存</el-button>
      </span>
    </el-dialog>
    <verifition
      mode="pop"
      captchaType="clickWord"
      :imgSize="{ width: '330px', height: '155px' }"
      ref="verify"
      @success="handleCheckCodeSuccess"
    />
  </div>
</template>
<script>
import Verifition from '@/components/verifition';
import UserApi from '@/api/systemSetting';
import LoginApi from '@/api/login';
import { mapMutations } from 'vuex';
export default {
  name: 'USER_INFO',
  components: {
    Verifition,
  },
  data () {
    return {
      userInfo: {},
      updateNameDialogVisible: false,
      updateNameForm: {
        name: '',
      },
      updateNameFormRules: {
        name: [
          { required: true, message: '请输入新姓名', trigger: 'blur' },
          { max: 30, message: '30字符内，不可为空', trigger: 'blur' },
        ],
      },
      updatePwdDialogVisible: false,
      updatePwdForm: {
        newPwd: '',
        repeatPwd: '',
        code: '',
        id: '',
        phone: '',
      },
      loginMsgTimerTick: 0,
      loginMsgTip: '发送短信验证码',
      updatePwdFormRules: {
        code: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }],
        newPwd: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { max: 11, min: 6, message: '请输入6-11位字符', trigger: 'blur' },
        ],
        repeatPwd: [
          { required: true, message: '请再次输入新密码', trigger: 'blur' },
          { max: 11, min: 6, message: '请输入6-11位字符', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (this.updatePwdForm.newPwd !== value) {
                callback(new Error('两次密码输入不一致'));
              }
              callback();
            },
            trigger: 'blur',
          },
        ],
      },
    };
  },
  watch: {
    updateNameDialogVisible () {
      if (!this.updateNameDialogVisible) {
        this.$refs.updateNameForm.resetFields();
        this.$refs.updateNameForm.clearValidate();
      }
    },
    updatePwdDialogVisible () {
      if (!this.updatePwdDialogVisible) {
        this.$refs.updatePwdForm.resetFields();
        this.$refs.updatePwdForm.clearValidate();
      }
    },
  },
  methods: {
    ...mapMutations({
      updateUserInfo: 'UPDATE_USERINFO',
    }),
    getUserInfo () {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      UserApi.getDetailEmploy({ id: userInfo.id, findDepartmentFlag: true, findRoleFlag: true }).then(r => {
        this.userInfo = r.body;
        this.updatePwdForm.phone = this.userInfo.phoneNumber;
        this.updateUserInfo(r.body);
      });
    },
    async handleCheckCode () {
      if (this.loginMsgTimerTick > 0) return;
      this.$refs.verify.show();
    },
    handleCheckCodeSuccess (captchaVO) {
      let phone = '';
      phone = this.updatePwdForm.phone;
      LoginApi.sendValidCode({ captchaVO, phone, captchaType: 'UPDATEPASSWORD' }).then(() => {
        if (!this.loginMsgTimerId) {
          this.loginMsgTimerTick = 120;
          this.loginMsgTimerId = setInterval(() => {
            this.loginMsgTimerTick--;
            this.loginMsgTip = `重新获取(${this.loginMsgTimerTick})`;
            if (this.loginMsgTimerTick === 0) {
              clearInterval(this.loginMsgTimerId);
              this.loginMsgTimerId = '';
              this.loginMsgTip = '获取验证码';
            }
          }, 1000);
        }
      });
    },
    handleSaveName () {
      this.$refs.updateNameForm.validate(valid => {
        if (valid) {
          UserApi.updateAccountName({
            id: this.userInfo.id,
            newName: this.updateNameForm.name,
          }).then(() => {
            this.getUserInfo();
            this.$message.success('姓名修改成功');
            this.updateNameDialogVisible = false;
          });
        }
      });
    },
    handleUpdatePwd () {
      this.$refs.updatePwdForm.validate(valid => {
        if (valid) {
          UserApi.updateAccountPassword({
            id: this.userInfo.id,
            newPassword: this.updatePwdForm.newPwd,
            code: this.updatePwdForm.code,
          }).then(() => {
            this.getUserInfo();
            this.$message.success('密码修改成功');
            this.updatePwdDialogVisible = false;
          });
        }
      });
    },
  },
  created () {
    this.getUserInfo();
  },
};
</script>
<style lang="scss" scoped>
.send_btn {
  color: #409EFF;
}
.disabled_send {
  cursor: no-drop;
}
</style>
